<template>
  <page-content :show-search="is_admin">
    <template #search>
      <table-search
        ref="search"
        :fields="search_fields"
        :default="search_default"
        @runSearch="runSearch"
        @changeField="searchChangeField"
      />
    </template>

    <template #default>
      <table-header
        ref="header"
        :title="title"
        :button-actions="header_actions"
        @runSearch="runSearch"
        :search-tips="$t('group.search_tips')"
      />

      <table-content
        :per-page="page_condition.pageSize"
        :per-page-dropdown-enabled="true"
        @perPageChange="perPageChange"
        :columns="columns"
        :rows="rows"
        :total-rows="total_rows"
        :current-page="current_page"
        :action-options="table_actions"
        @runTableAction="runTableAction"
        @sortChange="sortChange"
        @pageChange="pageChange"
        :permission="view_permission"
      />
    </template>

  </page-content>
</template>

<script>
import common from "@/common";
import TableContent from "@/components/TableContent";
import TableHeader from "@/components/TableHeader";
import TableSearch from "@/components/TableSearch";
import PageContent from "@/components/PageContent";

export default {
  components: {
    PageContent,
    TableSearch,
    TableHeader,
    TableContent,
  },
  data() {
    return {
      title: common.getMenuName('group'),
      is_admin: common.isAdmin(),
      view_permission: common.checkPermission('Gro_List'),
      rows: [],
      total_rows: 0,
      sort_condition: {},
      current_page: 1,
      page_condition: {
        pageIndex: 0,
        pageSize: common.getTablePerPage()
      },
      columns: [
        {
          label: 'common.row_index',
          field: 'row_index',
          sortable: false,
          width: '50px',
        },
        {
          label: 'group.groupName',
          field: 'groupName'
        },
        {
          label: 'group.groupType',
          field: 'isDefaultType',
          change: function (value) {
            return value ? common.getI18n('common.preset_all') : common.getI18n('common.custom')
          }
        },
        {
          label: 'group.alert',
          field: 'isDefaultAlert',
          change: function (value) {
            return value ? common.getI18n('group.alertPreset') : common.getI18n('common.custom')
          }
        },
        {
          label: 'group.isActive',
          field: 'isActive',
          width: '100px',
          change: function (value) {
            return value ? common.getI18n('common.enable') : common.getI18n('common.disable')
          }
        },
        {
          label: 'common.action',
          field: 'action',
          width: '200px',
          sortable: false,
        },
      ],
      header_actions: [
        {
          text: 'common.add',
          variant: 'primary',
          fun: this.addData,
          permission: 'Gro_Create'
        },
      ],
      table_actions: [
        {
          text: 'common.edit',
          variant: 'primary',
          fun: 'editData',
          permission: 'Gro_View'
        },
      ],
      search_fields: [
        {
          field: 'companyID',
          label: 'common.company',
          type: 'select',
          options: [],
          clearable: false,
          show: common.isAdmin(),
        },
        {
          field: 'facilityID',
          label: 'common.facility',
          type: 'select',
          options: [],
          clearable: false,
          show: common.isAdmin(),
        },
        {
          field: 'isActive',
          label: 'group.isActive',
          type: 'select',
          options: [
            {text: this.$t('common.enable'), value: true},
            {text: this.$t('common.disable'), value: false},
          ]
        },
      ],
      search_default: {
        companyID: common.getCompanyId(),
        facilityID: common.getFacilityId(),
      }
    }
  },
  mounted() {
    if (!common.isAdmin()) {
      this.getList()
    } else {
      common.getSearchCompanyOptions('companyID', this)
      common.getSearchFacilityOptions('facilityID', common.getAdminCompanyId(), this)
      this.getList()
    }
  },

  methods: {
    getList: function () {
      if (!this.view_permission) return

      const api = '/group/GetGroupList'
      const search = this.getSearchCondition()

      const url = common.getTableUrl(api, search, this.sort_condition, this.page_condition)

      common.apiGetData(url).then(res => {
        this.total_rows = res.data.total
        this.rows = res.data.groups
      })
    },
    perPageChange(size) {
      this.page_condition.pageIndex = 0
      this.page_condition.pageSize = size
      common.setTablePerPage(size)
      this.getList()
    },
    addData: function () {
      this.$router.push({name: 'group_add'})
    },
    editData: function (data) {
      this.$router.push({name: 'group_edit', params: {id: common.encrypt(data.groupID)}})
    },
    runSearch: function () {
      this.current_page = 1
      this.page_condition.pageIndex = 0
      const vmOfSearch = this.$refs.search
      common.syncSearchFields({
        companyID: vmOfSearch.list.companyID,
        facilityID: vmOfSearch.list.facilityID,
        floorID: vmOfSearch.list.floorID,
        roomID: vmOfSearch.list.roomID,
      })
      this.getList()
    },
    async searchChangeField(field, value) {
      await common.searchChangeField({
        fieldName: field,
        newValue: value,
        currentVm: this,
        searchVm: this.$refs.search,
        isSyncGlobally: false
      })
    },
    runTableAction: function (action, data) {
      this[action](data)
    },
    getSearchCondition: function () {
      this.$refs.search.buildSearchCondition()

      let where = {}
      where['searchContent'] = this.$refs.header.searchContent
      where = Object.assign(where, this.$refs.search.search_condition)

      return where
    },
    sortChange: function (value) {
      this.sort_condition = value
      this.getList()
    },
    pageChange: function (value) {
      this.current_page = value
      this.page_condition.pageIndex = value - 1
      this.getList()
    },
  }
}
</script>
